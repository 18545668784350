import moment from 'moment';

export function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function formatString(string, length) {
  if (!string) return '';
  if (length < 5) return string;
  if (string.length <= length) return string;
  return (
    string.slice(0, 8) +
    '...' +
    string.slice(string.length - length + 5, string.length)
  );
}

export function generateWeekGroups(year) {
  let weeksGrouped = [];
  let startOfYear = new Date(year, 0, 1); // January 1st of the given year
  let currentWeekStart = startOfYear;

  const MS_PER_DAY = 24 * 60 * 60 * 1000; // Milliseconds in a day
  const DAYS_IN_WEEK = 7;
  const WEEKS_IN_GROUP = 4;

  for (let i = 1; i <= 52; i += WEEKS_IN_GROUP) {
    let weekTitle = `Week ${i} - ${i + WEEKS_IN_GROUP - 1}`;
    let groupStartDate = new Date(currentWeekStart);

    // Calculate the end of the 4th week in this group
    let groupEndDate = new Date(
      groupStartDate.getTime() +
        (DAYS_IN_WEEK * WEEKS_IN_GROUP - 1) * MS_PER_DAY
    );

    // Array to hold each week's details within this group
    let weeksArray = [];

    for (let j = 0; j < WEEKS_IN_GROUP; j++) {
      let weekNumber = i + j;
      let weekStartDate = new Date(
        currentWeekStart.getTime() + j * DAYS_IN_WEEK * MS_PER_DAY
      );
      let weekEndDate = new Date(
        weekStartDate.getTime() + (DAYS_IN_WEEK - 1) * MS_PER_DAY
      );

      weeksArray.push({
        week_number: `Week ${weekNumber}`,
        start_date: moment(weekStartDate).format('YYYY-MM-DD'),
        end_date: moment(weekEndDate).format('YYYY-MM-DD')
      });
    }

    // Push the group object containing the 4 weeks array
    weeksGrouped.push({
      week_title: weekTitle,
      weeks: weeksArray,
      start_date: moment(groupStartDate).format('YYYY-MM-DD'),
      end_date: moment(groupEndDate).format('YYYY-MM-DD')
    });

    // Move the currentWeekStart to the beginning of the next group (4 weeks later)
    currentWeekStart = new Date(
      currentWeekStart.getTime() + DAYS_IN_WEEK * WEEKS_IN_GROUP * MS_PER_DAY
    );
  }

  return weeksGrouped;
}

export const groupDataByWeek = (data) => {
  // Helper function to get the Monday of the week for a given date
  const getStartOfWeek = (date) => moment(date).startOf('isoWeek'); // isoWeek starts from Monday

  // Create a map to store weeks by their Monday date
  const weeksMap = {};

  data.forEach((item) => {
    const itemDate = moment(item.date, 'YYYY-MM-DD');
    const startOfWeek = getStartOfWeek(itemDate).format('YYYY-MM-DD'); // Monday of the week

    // If this week's Monday isn't in the map, initialize it
    if (!weeksMap[startOfWeek]) {
      weeksMap[startOfWeek] = [];
    }

    // Push the current day to the corresponding week
    weeksMap[startOfWeek].push(item);
  });

  // Convert the weeks map to an array of weeks, each week containing an array of days
  const weeksArray = Object.keys(weeksMap).map((startOfWeek) => ({
    weekStart: startOfWeek,
    weekEnd: moment(startOfWeek).endOf('isoWeek').format('YYYY-MM-DD'), // Sunday of the week
    days: weeksMap[startOfWeek],
    dayOfTheWeeks: [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday'
    ]
  }));

  return weeksArray;
};
