import { progressEventHandler } from 'utilities/utils';
import axios, { getBaseDomainURL } from './axios.config';

// NB: Errors are handled at the components level
export function createEmployeeFastMode(payload) {
  return axios
    .post(`${getBaseDomainURL()}/employees/post_employee_form_once/`, payload)
    .then(({ data }) => data);
}

export function editEmployeeFastMode({ payload, profile }) {
  return axios
    .put(
      `${getBaseDomainURL()}/employees/${profile}/update_employee_form_once/`,
      payload
    )
    .then(({ data }) => data);
}

export function getNextId() {
  return axios
    .get(`${getBaseDomainURL()}/employees/next_id/`)
    .then(({ data }) => data);
}

export function getGrowthFlowChart() {
  return axios
    .get(`${getBaseDomainURL()}/employees/dashboard/employee_growth_flow/`)
    .then(({ data }) => data);
}

export function getTurnOver() {
  return axios
    .get(`${getBaseDomainURL()}/employees/dashboard/employee_turn_over/`)
    .then(({ data }) => data);
}
// ===================== BRANCHES ====================
export function getBranches(payload) {
  let url = new URL('https://example.com');
  let params = new URLSearchParams(url.search);
  if (payload?.search) params.set('search', payload.search);
  const newUrl = params.toString();
  return axios
    .get(`${getBaseDomainURL()}/regions/?${newUrl}`)
    .then(({ data }) => data);
}

export function getLocationsProfile(key) {
  let page = key.queryKey[1].page;
  return axios
    .get(`${getBaseDomainURL()}/regions/?page=${page}`)
    .then(({ data }) => data);
}

export function getHrOnboardingStatus(key) {
  let { type, business_id } = key.queryKey[1];
  return axios
    .get(
      `${getBaseDomainURL()}/app_onboarding/?type=${type}&business_id=${business_id}`
    )
    .then(({ data }) => data);
}
export function updateOnboardingCall(payload) {
  return axios
    .post(`${getBaseDomainURL()}/app_onboarding/`, payload)
    .then(({ data }) => data);
}

export function updateSecondVisit(payload) {
  return axios
    .put(`${getBaseDomainURL()}/app_onboarding/${payload.id}/`, payload)
    .then(({ data }) => data);
}

export function createBranch(name) {
  return axios
    .post(`${getBaseDomainURL()}/regions/`, {
      name
    })
    .then(({ data }) => data);
}

export function reportAssset({ id, payload }) {
  return axios
    .post(
      `${getBaseDomainURL()}/asset-management/assets/${id}/issues/report/`,
      payload
    )
    .then(({ data }) => data);
}

export function createBranch2(payload) {
  return axios
    .post(`${getBaseDomainURL()}/regions/`, payload)
    .then(({ data }) => data);
}
//=============================== DEPARTMENTS =======================
export function createDepartment(name, setResponse) {
  return axios
    .post(`${getBaseDomainURL()}/departments/`, {
      name
    })
    .then((res) => setResponse(res));
}

export function createDepartment2({ name }) {
  return axios
    .post(`${getBaseDomainURL()}/departments/`, {
      name
    })
    .then(({ data }) => data);
}

export function addCompany(payload) {
  return axios
    .post(`${getBaseDomainURL()}/businesses/add/`, payload)
    .then(({ data }) => data);
}

export function checkCompanyAddStatus(payload) {
  return axios
    .post('/businesses/check_create_status/', payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function createDepartmentProfile(payload) {
  return axios
    .post(`${getBaseDomainURL()}/departments/`, payload)
    .then(({ data }) => data);
}
export function updateDepartmentProfile({ payload, id }) {
  return axios
    .patch(`${getBaseDomainURL()}/departments/${id}/`, payload)
    .then(({ data }) => data);
}

export function deleteDepartment({ id }) {
  return axios
    .delete(`${getBaseDomainURL()}/departments/${id}/`)
    .then(({ data }) => data);
}

export function deleteLocation({ id }) {
  return axios
    .delete(`${getBaseDomainURL()}/regions/${id}/`)
    .then(({ data }) => data);
}

export function deleteEmployees({ id }) {
  return axios
    .delete(`${getBaseDomainURL()}/employees/${id}/`)
    .then(({ data }) => data);
}

export async function deleteEmployeeCompensationHistory(id) {
  try {
    const { data: data_1 } = await axios.delete(
      `${getBaseDomainURL()}/employees/${id}/delete_compensation`
    );
    return data_1;
  } catch (error) {
    if (error.response === 400) {
      throw error;
    }
    throw error.response.data;
  }
}

export function deleteBulkBenefits(payload) {
  return axios
    .post(`${getBaseDomainURL()}/benefits/bulk_delete/`, payload)
    .then(({ data }) => data);
}

export function assignBulkBenefits(payload) {
  return axios
    .post(`${getBaseDomainURL()}/benefits/bulk_assign/`, payload)
    .then(({ data }) => data);
}

export function getDepartments(params) {
  return axios
    .get(`${getBaseDomainURL()}/departments/`, { params })
    .then(({ data }) => data);
}

export function getAllEmployeesWithoutPagination() {
  return axios
    .get(`${getBaseDomainURL()}/employees/all/`)
    .then(({ data }) => data);
}

export function getEmployeesWithFilter(params) {
  // const params = key.queryKey[1];
  return axios
    .get(`${getBaseDomainURL()}/employees`, { ...params })
    .then(({ data }) => data);
}

export function getAllDepartmentWithoutPagination() {
  return axios
    .get(`${getBaseDomainURL()}/departments/all/`)
    .then(({ data }) => data);
}

export function getAllLocationtWithoutPagination() {
  return axios
    .get(`${getBaseDomainURL()}/regions/all/`)
    .then(({ data }) => data);
}

export function getAllJobstWithoutPagination() {
  return axios.get(`${getBaseDomainURL()}/jobs/all/`).then(({ data }) => data);
}

export function getDepartmentsBreakdown() {
  return axios
    .get(`${getBaseDomainURL()}/employees/dashboard/department_breakdown/`)
    .then(({ data }) => data);
}

export function getDepartments2(key) {
  let page = key.queryKey[1].page;
  return axios
    .get(`${getBaseDomainURL()}/departments/?page=${page}`)
    .then(({ data }) => data);
}

export function getDocuments(key) {
  let page = key.queryKey[1].page;
  let searchQuery = key.queryKey[1].search;

  return axios
    .get(`${getBaseDomainURL()}/hr/company_files/`, {
      params: {
        page: page,
        search: searchQuery
      },
      headers: {
        // Include any headers required for the API request
      }
    })
    .then(({ data }) => data);
}

export function shareBulkDocument(payload) {
  return axios
    .post(`${getBaseDomainURL()}/hr/company_files/share/`, payload)
    .then(({ data }) => data);
}

export function createCompanySignature(payload) {
  return axios
    .post(`${getBaseDomainURL()}/hr/company_signatures/`, payload)
    .then(({ data }) => data);
}

export function getCompanySignatures() {
  return axios
    .get(`${getBaseDomainURL()}/hr/company_signatures/?page_size=50`)
    .then(({ data }) => data);
}

export function getOneEmployeeDocuments({ id, ...payload }) {
  // const encodeStatus = encodeURI(status || '');
  return (
    axios
      // .get(`${getBaseDomainURL()}/employees/${id}/document/?status=${status}`, {
      .get(`${getBaseDomainURL()}/employees/${id}/document/`, {
        params: { ...payload }
      })
      .then(({ data }) => data)
  );
}
export function postSelfAccess(payload) {
  return axios
    .post(`${getBaseDomainURL()}/employee_user_invitations/`, payload)
    .then(({ data }) => data);
}

export function getEmployeeInvites(key) {
  let page = key.queryKey[1].page;
  return axios
    .get(`${getBaseDomainURL()}/employee_user_invitations/?page=${page}`)
    .then(({ data }) => data);
}

export function resendEmployeeInvites(id) {
  return axios
    .post(`${getBaseDomainURL()}/employee_user_invitations/${id}/resend/`)
    .then(({ data }) => data);
}

export function cancelEmployeeInvites(id) {
  return axios
    .delete(`${getBaseDomainURL()}/employee_user_invitations/${id}/`)
    .then(({ data }) => data);
}

// ================================ JOBS ================================
export function createJobTitle(title, setResponse) {
  axios
    .post(`${getBaseDomainURL()}/jobs/`, {
      title: title.title
    })
    .then((res) => setResponse(res));
}

export function getJobsProfile(key) {
  let params = key.queryKey[1];
  return axios
    .get(`${getBaseDomainURL()}/jobs/`, { params })
    .then(({ data }) => data);
}

export function createJob({ title }) {
  axios
    .post(`${getBaseDomainURL()}/jobs/`, {
      title: title
    })
    .then((res) => res);
}
export function updateJob({ payload, id }) {
  axios.patch(`${getBaseDomainURL()}/jobs/${id}/`, payload).then((res) => res);
}
export function deleteJob({ id }) {
  axios.delete(`${getBaseDomainURL()}/jobs/${id}/`).then((res) => res);
}

export function createJobTitleNew({ title, setJobTitle, setLoading1 }) {
  axios
    .post(`${getBaseDomainURL()}/jobs/`, {
      title
    })
    .then((res) => {
      setJobTitle({ label: res.data.title, value: res.data.id });
      setLoading1(false);
    })

    .catch((error) => {
      setLoading1(false);
      throw error;
    });
}

export function createJobTitleEdit({ title, setJobTitle, setLoading1 }) {
  axios
    .post(`${getBaseDomainURL()}/jobs/`, {
      title
    })
    .then((res) => {
      setJobTitle({ title: res.data.title, id: res.data.id });
      setLoading1(false);
    })

    .catch((error) => {
      setLoading1(false);
      throw error;
    });
}

export function getJobs(payload) {
  let url = new URL('https://example.com');
  let params = new URLSearchParams(url.search);
  if (payload?.search) params.set('search', payload.search);
  const newUrl = params.toString();
  return axios
    .get(`${getBaseDomainURL()}/jobs/?${newUrl}`)
    .then(({ data }) => data);
}

export function updateJobInfo(payload) {
  return axios
    .post(
      `${getBaseDomainURL()}/employees/${payload.id}/update-job-details/`,
      payload
    )
    .then(({ data }) => data);
}

export function deleteBulkDocument(payload) {
  return axios
    .post(`${getBaseDomainURL()}/hr/company_files/bulk_delete/`, payload)
    .then(({ data }) => data);
}

export function updateCompensation({ id, payload }) {
  return axios
    .post(
      `${getBaseDomainURL()}/employees/${id}/update_job_compensation/`,
      payload
    )
    .then(({ data }) => data);
}

export function renameCompanyDocs(payload) {
  return axios
    .put(`${getBaseDomainURL()}/hr/company_files/${payload.id}/`, payload)
    .then(({ data }) => data);
}

// ================================== EMPLOYEES =================

export function getEmployees(payload) {
  let params = {};
  for (let [key, value] of Object.entries(payload)) {
    params[key] = value === '' ? null : value;
  }
  params = {
    ...params,
    page_size: payload?.page_size ?? 50
  };

  return axios
    .get(`${getBaseDomainURL()}/employees/`, { params })
    .then(({ data }) => data);
}

export function getAllEmployees() {
  return axios
    .get(`${getBaseDomainURL()}/employees/all/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}
export function getAllEmployeesV2(params) {
  return axios
    .get(`${getBaseDomainURL()}/employees/`, { params })
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function createEmployee(payload) {
  return axios
    .post(`${getBaseDomainURL()}/employees/`, payload)
    .then(({ data }) => data);
}

export function getOneEmployee(id) {
  return axios
    .get(`${getBaseDomainURL()}/employees/${id}/`)
    .then(({ data }) => data);
}

export function getOneEmployeeCompensation(id) {
  return axios
    .get(`${getBaseDomainURL()}/employees/${id}/compensations`)
    .then(({ data }) => data);
}

export function getNextOfKin(id) {
  return axios
    .get(`${getBaseDomainURL()}/employees/${id}/next-of-kin/`)
    .then(({ data }) => data);
}

export function getOneEmployeeUpdateRequest(params) {
  return axios
    .get(`${getBaseDomainURL()}/employees/get_profile_change_requests/`, {
      params
    })
    .then(({ data }) => data);
}

export function markEmployeeEditRequest({ id, payload }) {
  return axios
    .post(
      `${getBaseDomainURL()}/employees/${id}/approve_profile_update_request/`,
      payload
    )
    .then((res) => res.data);
}
export function updateNextOfKin(payload) {
  return axios
    .put(
      `${getBaseDomainURL()}/employees/${payload.id}/update-next-of-kin/`,
      payload
    )
    .then(({ data }) => data);
}

export function updateEmployeeRecords(payload) {
  return axios
    .put(`${getBaseDomainURL()}/employees/${payload.id}/`, payload)
    .then(({ data }) => data);
}

export function getEmergencyContact(id) {
  return axios
    .get(`${getBaseDomainURL()}/employees/${id}/emergency-contact/`)
    .then(({ data }) => data);
}

export function getCompensationHistory({ id, page }) {
  return axios
    .get(
      `${getBaseDomainURL()}/employees/${id}/compensation_history/?page=${page}&page_size=1000`
    )
    .then(({ data }) => data);
}

export function updateEmergencyContact(payload) {
  return axios
    .put(
      `${getBaseDomainURL()}/employees/${payload.id}/update-emergency-contact/`,
      payload
    )
    .then(({ data }) => data);
}

export function updatePhoto(payload) {
  return axios
    .put(
      `${getBaseDomainURL()}/employees/${payload.id}/update-photo/`,
      payload.photo
    )
    .then(({ data }) => data);
}

export function updateJobDetails({ id, payload }) {
  return axios
    .post(`${getBaseDomainURL()}/employees/${id}/update_job_details/`, payload)
    .then(({ data }) => data);
}

//

export function getAllEmployeeWithoutPagination(params) {
  return axios
    .get(`${getBaseDomainURL()}/employees/all/`, { params })
    .then(({ data }) => data);
}

export function getAllEmployeeWithFilter(params) {
  return axios
    .get(`${getBaseDomainURL()}/employees/filter/`, { params })
    .then(({ data }) => data);
}

//

export function deletePhoto(payload) {
  return axios
    .put(
      `${getBaseDomainURL()}/employees/${payload.id}/delete-photo/`,
      payload.photo
    )
    .then(({ data }) => data);
}

//=============================== TIMEOFF =======================
export function getAllTimeoffPolicies(payload) {
  let url = new URL('https://example.com');
  let params = new URLSearchParams(url.search);
  if (payload?.search) params.set('search', payload.search);
  const newUrl = params.toString();
  return axios
    .get(`${getBaseDomainURL()}/timeoff_policy/?${newUrl}&page_size=50`)
    .then(({ data }) => data);
}

export function getTimeoffPolicy(timeoffId, query) {
  return axios
    .get(`${getBaseDomainURL()}/timeoff_policy/${timeoffId}/`, {
      params: query
    })
    .then(({ data }) => data);
}

export function getTimeoffRequest(payload) {
  let url = new URL('https://example.com');
  let params = new URLSearchParams(url.search);
  if (payload?.search) params.set('search', payload.search);
  const newUrl = params.toString();
  return axios
    .get(`${getBaseDomainURL()}/timeoff_requests/?${newUrl}`)
    .then(({ data }) => data);
}

export function createTimeoffPolicy(payload) {
  return axios
    .post(`${getBaseDomainURL()}/timeoff_policy/`, payload)
    .then(({ data }) => data);
}

export function deleteTimeoffPolicy(id) {
  return axios
    .delete(`${getBaseDomainURL()}/timeoff_policy/${id}`)
    .then(({ data }) => data);
}
export function deleteBulkTimeoffPolicy(ids) {
  return axios
    .delete(`${getBaseDomainURL()}/timeoff_policy/bulk_delete/`, {
      data: { ids }
    })
    .then(({ data }) => data);
}

export function editTimeoffPolicy(payload) {
  return axios
    .put(`${getBaseDomainURL()}/timeoff_policy/${payload.id}/`, payload)
    .then(({ data }) => data);
}

export function getTimeoffPolicyEmployees({ id, page }) {
  return axios
    .get(`${getBaseDomainURL()}/timeoff_policy/${id}/timeoff/?page=${page}`)
    .then(({ data }) => data);
}

export function toggleDefault(payload) {
  const { id, is_default } = payload;
  return axios
    .post(`${getBaseDomainURL()}/timeoff_policy/${id}/toggle_default/`, {
      is_default
    })
    .then(({ data }) => data);
}

export function assignEmployeeToTimeoff(payload) {
  const { employee, timeoff_policy_pk } = payload;
  return axios
    .post(
      `${getBaseDomainURL()}/timeoff_policy/${timeoff_policy_pk}/timeoff/`,
      {
        employee
      }
    )
    .then(({ data }) => data);
}
export function assignEmployeeToTimeoffV2(payload) {
  const { employees, timeoffId } = payload;
  return axios
    .post(`${getBaseDomainURL()}/timeoff_policy/${timeoffId}/assign/`, {
      employees
    })
    .then(({ data }) => data);
}

export function deleteAllTimeoff(payload) {
  return axios
    .put(
      `${getBaseDomainURL()}/timeoff_policy/${payload.id}/timeoff/delete_all/`
    )
    .then(({ data }) => data);
}
export function deleteSingleTimeoffEmployee(payload) {
  return axios
    .delete(
      `${getBaseDomainURL()}/timeoff_policy/${
        payload.timeoff_policy_pk
      }/timeoff/${payload.id}/`
    )
    .then(({ data }) => data);
}

export function declineTimeoffRequest(id, reason = '') {
  return axios
    .post(`${getBaseDomainURL()}/timeoff_requests/${id}/decline/`, { reason })
    .then(({ data }) => data);
}

export function acceptTimeoffRequest(payload) {
  return axios
    .post(`${getBaseDomainURL()}/timeoff_taken/`, payload)
    .then(({ data }) => data);
}

export const removeEmployeeFromAssignedList = ({ id, payload }) => {
  return axios
    .post(`${getBaseDomainURL()}/timeoff_policy/${id}/unassign/`, payload)
    .then(({ data }) => data);
};

export function getTimeoffTaken(payload) {
  return axios
    .get(`${getBaseDomainURL()}/timeoff_taken/`, { params: payload })
    .then(({ data }) => data);
}

export function getTimeOffUnassignedEmployees(timeoffId, query) {
  return axios
    .get(`${getBaseDomainURL()}/timeoff_policy/${timeoffId}/`, {
      params: query
    })
    .then((res) => res.data.employees_unassigned)
    .catch((err) => {
      throw err;
    });
}

export function adjustTimeoffTaken(payload) {
  return axios
    .post(`${getBaseDomainURL()}/timeoff_taken/adjustments/`, payload)
    .then((res) => res.data.employees_unassigned)
    .catch((err) => {
      throw err;
    });
}
export function assignBulkTimeOffs(payload) {
  return axios
    .post(`${getBaseDomainURL()}/timeoff_policy/multi_assign/`, payload)
    .then((res) => res.data);
}

// ------------------------ timeoff end ----------------
export function getBanks() {
  return axios.get(`${getBaseDomainURL()}/banks/`).then(({ data }) => data);
}
export function getPensionProviders() {
  return axios
    .get(`${getBaseDomainURL()}/pension_providers/`)
    .then(({ data }) => data);
}

export function updateBankdetails({ id, payload }) {
  return axios
    .post(
      `${getBaseDomainURL()}/employees/${id}/update_pension_bank_account/`,
      payload
    )
    .then(({ data }) => data);
}
//=============================== BENEFIT =======================
export function getBenefits(key) {
  let page = key.queryKey[1].page;
  return axios
    .get(`${getBaseDomainURL()}/benefits/?page=${page}`)
    .then(({ data }) => data);
}

export function createBenefit(payload) {
  return axios
    .post(`${getBaseDomainURL()}/benefits/`, payload)
    .then(({ data }) => data);
}

export function editBenefit({ payload, id }) {
  return axios
    .put(`${getBaseDomainURL()}/benefits/${id}/`, payload)
    .then(({ data }) => data);
}

export function deleteBenefit({ id }) {
  return axios
    .delete(`${getBaseDomainURL()}/benefits/${id}/`)
    .then(({ data }) => data);
}
export function deleteBenefitV2(id) {
  return axios
    .post(`${getBaseDomainURL()}/benefits/bulk_delete/`, { ids: [id] })
    .then(({ data }) => data);
}
export function getBenefitEmployees({ id, page }) {
  return axios
    .get(`${getBaseDomainURL()}/benefits/${id}/employees?page=${page}`)
    .then(({ data }) => data);
}
// assign benefit to an employee
export function assignEmployeeToBenefit(payload) {
  const { employee, timeoff_policy_pk } = payload;
  return axios
    .put(`${getBaseDomainURL()}/benefits/${timeoff_policy_pk}/assign/`, {
      employee
    })
    .then(({ data }) => data);
}
// get assigned and unassigned employee for a particlular benefit {id : benefitId}
export function getBenefitUnassignedEmployees({ id, search }) {
  return axios
    .get(`${getBaseDomainURL()}/benefits/${id}/get_assigned_unassigned_list/`, {
      params: { search }
    })
    .then(({ data }) => data);
}

export function deleteAllBenefits(payload) {
  return axios
    .put(`${getBaseDomainURL()}/benefits/${payload.id}/delete_all/`)
    .then(({ data }) => data);
}
export function deleteSingleBenefitEmployee(payload) {
  return axios
    .put(`${getBaseDomainURL()}/benefits/${payload.id}/unassign/`, {
      employee: payload.employee
    })
    .then(({ data }) => data);
}

export function toggleDefaultBenefit(payload) {
  const { id, is_default } = payload;
  return axios
    .post(`${getBaseDomainURL()}/benefits/${id}/toggle_default/`, {
      is_default
    })
    .then(({ data }) => data);
}

export function getAvailableBenefit(employee_pk) {
  return axios
    .get(`${getBaseDomainURL()}/employees/${employee_pk}/benefits/`)
    .then(({ data }) => data);
}

// export function getAvailableTraining(employee_pk) {
//   return axios
//     .get(`${getBaseDomainURL()}/employees/${employee_pk}/training/`)
//     .then(({ data }) => data);
// }
export function getAvailableTraining(employee_pk) {
  return axios
    .get(
      `${getBaseDomainURL()}/training/employee/assigned/?employee_id=${employee_pk}`
    )
    .then(({ data }) => data);
}

//=============================== HOME =======================

export function getGrowthFlow() {
  return axios
    .get(`${getBaseDomainURL()}/employees/dashboard/count/`)
    .then(({ data }) => data);
}

export function getAttendance() {
  return axios
    .get(`${getBaseDomainURL()}/employees/dashboard/attendance/`)
    .then(({ data }) => data);
}

export function getGender() {
  return axios
    .get(`${getBaseDomainURL()}/employees/dashboard/gender_distribution/`)
    .then(({ data }) => data);
}

export function getPeoplePerDept() {
  return axios
    .get(`${getBaseDomainURL()}/employees/dashboard/department_breakdown/`)
    .then(({ data }) => data);
}

export function getBirthdays() {
  return axios
    .get(`${getBaseDomainURL()}/employees/dashboard/birthdays/?status=active`)
    .then(({ data }) => data);
}

export function getUpcoming() {
  return axios
    .get(`${getBaseDomainURL()}/employees/dashboard/birthdays/?status=upcoming`)
    .then(({ data }) => data);
}

export function getAnniversary() {
  return axios
    .get(
      `${getBaseDomainURL()}/employees/dashboard/job_anniversary/?status=active`
    )
    .then(({ data }) => data);
}

export function getUpcomingAnniversary() {
  return axios
    .get(
      `${getBaseDomainURL()}/employees/dashboard/job_anniversary/?status=upcoming`
    )
    .then(({ data }) => data);
}

export function getNewHire() {
  return axios
    .get(`${getBaseDomainURL()}/employees/dashboard/new_hire/`)
    .then(({ data }) => data);
}

export function getWhoIsOutLeave() {
  return axios
    .get(
      `${getBaseDomainURL()}/timeoff_taken/widgets/whos_out/?category=timeoff`
    )
    .then(({ data }) => data);
}

export function getWhoIsOutRemote() {
  return axios
    .get(
      `${getBaseDomainURL()}/timeoff_taken/widgets/whos_out/?category=work_from_home`
    )
    .then(({ data }) => data);
}

//=============================== TRAINING =======================

export function createTraining(payload) {
  return axios
    .post(`${getBaseDomainURL()}/training/`, payload)
    .then(({ data }) => data);
}

export function getTraining(key) {
  let page = key.queryKey[1].page;
  return axios
    .get(`${getBaseDomainURL()}/training/?page=${page}`)
    .then(({ data }) => data);
}

export function getSingleTraining(id) {
  return axios
    .get(`${getBaseDomainURL()}/training/${id}`)
    .then(({ data }) => data);
}

export function getSingleTrainingEmployees({ id, page }) {
  return axios
    .get(`${getBaseDomainURL()}/training/${id}/employees?page=${page}`)
    .then(({ data }) => data);
}

export function editSingleTraining({ id, payload }) {
  return axios
    .put(`${getBaseDomainURL()}/training/${id}/`, payload)
    .then(({ data }) => data);
}

export function deleteSingleTraining({ id }) {
  return axios
    .delete(`${getBaseDomainURL()}/training/${id}/`)
    .then(({ data }) => data);
}

export function getAvailableTimeoff(employee_pk) {
  return axios
    .get(`${getBaseDomainURL()}/employees/${employee_pk}/timeoff/`)
    .then(({ data }) => data);
}

export function getTimeoffHistory(employee_pk) {
  return axios
    .get(
      `${getBaseDomainURL()}/employees/${employee_pk}/timeoff_taken/?status=history`
    )
    .then(({ data }) => data);
}

export function requestTimeoff({ payload, employee_pk }) {
  return axios
    .post(
      `${getBaseDomainURL()}/employees/${employee_pk}/timeoff_requests/`,
      payload
    )
    .then(({ data }) => data);
}

export function adjustTimeoff({ payload, employee_pk }) {
  return axios
    .post(
      `${getBaseDomainURL()}/timeoff_adjustments/?timeoff=${employee_pk}`,
      payload
    )
    .then(({ data }) => data);
}

export function recordTimeoffForEmployee({ payload }) {
  return axios
    .post(`${getBaseDomainURL()}/timeoff_taken/record/`, payload)
    .then(({ data }) => data);
}

export function getRequestedTimeoff(employee_pk) {
  return axios
    .get(`${getBaseDomainURL()}/employees/${employee_pk}/timeoff_requests/`)
    .then(({ data }) => data);
}

export function getTimeoffTaken2(status, employee_pk) {
  return axios
    .get(
      `${getBaseDomainURL()}/employees/${employee_pk}/timeoff_taken/?status=${status}`
    )
    .then(({ data }) => data);
}

export function cancelRequest({ id, employee_pk }) {
  return axios
    .delete(
      `${getBaseDomainURL()}/employees/${employee_pk}/timeoff_requests/${id}/`
    )
    .then(({ data }) => data);
}

export function onboardingList(key) {
  let url = new URL('https://example.com');
  let params = new URLSearchParams(url.search);
  let { page, search, onboarded } = key.queryKey[1];
  if (search) params.set('search', search);
  if (onboarded !== '') params.set('onboarded', onboarded);
  if (onboarded === '') params.delete('onboarded');
  const newUrl = params.toString();
  return axios
    .get(`${getBaseDomainURL()}/employees/onboarding/?${newUrl}&page=${page}`)
    .then(({ data }) => data);
}

export function createEmployeeOnboarding({ payload, id }) {
  return axios
    .post(`${getBaseDomainURL()}/employees/${id}/onboarding/`, payload)
    .then(({ data }) => data);
}

export function getOneEmployeeAssetsAndVehicles(employee_pk) {
  return axios
    .get(`${getBaseDomainURL()}/employees/${employee_pk}/asset_vehicles/`)
    .then(({ data }) => data);
}
export function getOneEmployeeAssets(params) {
  return axios
    .get(`${getBaseDomainURL()}/asset-management/assets/assigned_to/`, {
      params
    })
    .then(({ data }) => data);
}
export function bulkAssignAsset(payload) {
  return axios
    .post(`${getBaseDomainURL()}/benefits/bulk_assign/`, payload)
    .then(({ data }) => data);
}

export function bulkAddEmployee(payload) {
  return axios
    .post(`${getBaseDomainURL()}/employees/import/`, payload)
    .then(({ data }) => data);
}

export function setupCheckpoint() {
  return axios
    .get(`${getBaseDomainURL()}/hr/checkpoints/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function markEmployeesAsOnboarded(payload) {
  return axios
    .post(
      `${getBaseDomainURL()}/employees/onboarding/mark_as_complete/`,
      payload
    )
    .then(({ data }) => data);
}

export function uploadOnboardingDocs(payload, progressHandler) {
  return axios
    .post(`${getBaseDomainURL()}/uploads/temporary/`, payload.file, {
      onUploadProgress: progressEventHandler(progressHandler)
    })
    .then(({ data }) => data);
}

export function uploadDocs(payload) {
  return axios
    .post(`${getBaseDomainURL()}/hr/company_files/`, payload)
    .then(({ data }) => data);
}

export function uploadSingleEmployeeDocs({ id, payload }) {
  return axios
    .post(`${getBaseDomainURL()}/employees/${id}/document/`, payload)
    .then(({ data }) => data);
}

export function deleteCompanyDocs({ id }) {
  return axios
    .delete(`${getBaseDomainURL()}/hr/company_files/${id}`)
    .then(({ data }) => data);
}

export function deleteOneEmployeeDocs({ id }) {
  return axios
    .delete(`${getBaseDomainURL()}/documents/${id}`)
    .then(({ data }) => data);
}

export function getTasks(id, is_completed) {
  return axios
    .get(
      `${getBaseDomainURL()}/employees/onboarding/${id}/tasks/?is_completed=${is_completed}`
    )
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function createTasks({ payload, id }) {
  return axios
    .post(`${getBaseDomainURL()}/employees/onboarding/${id}/tasks/`, payload)
    .then(({ data }) => data);
}

export function editTasks({ payload, onboarding_id, id }) {
  return axios
    .put(
      `${getBaseDomainURL()}/employees/onboarding/${onboarding_id}/tasks/${id}/`,
      payload
    )
    .then(({ data }) => data);
}

export function deleteTask({ onboarding_id, id }) {
  return axios
    .delete(
      `${getBaseDomainURL()}/employees/onboarding/${onboarding_id}/tasks/${id}/`
    )
    .then(({ data }) => data);
}

export function toggleCompletedTask({ onboarding_id, id, payload }) {
  return axios
    .put(
      `${getBaseDomainURL()}/employees/onboarding/${onboarding_id}/tasks/${id}/toggle_completed/`,
      payload
    )
    .then(({ data }) => data);
}

export function deleteCompletedTask({ onboarding_id }) {
  return axios
    .delete(
      `${getBaseDomainURL()}/employees/onboarding/${onboarding_id}/tasks/delete_completed/`
    )
    .then(({ data }) => data);
}

export function validateBankAccount(payload) {
  return axios
    .post(`${getBaseDomainURL()}/banks/account_number_validation/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}
//

export function getTurnoverLengthOfServie() {
  return axios
    .get(`${getBaseDomainURL()}/hr/reports/length_of_service_range/`)
    .then(({ data }) => data);
}

export function getTurnOverData(key) {
  let page = key.queryKey[1].page;
  return axios
    .get(`${getBaseDomainURL()}/hr/reports/turnover_data/?page=${page}`)
    .then(({ data }) => data);
}

export function getGainerAndLeaverData(key) {
  let { department, location, start_date, end_date } = key.queryKey[1];
  let url = new URL('https://example.com');
  let params = new URLSearchParams(url.search);
  if (department) params.set('department', department);
  if (location) params.set('location', location);
  if (start_date) params.set('start_date', start_date);
  if (end_date) params.set('department', end_date);
  const newUrl = params.toString();
  return axios
    .get(`${getBaseDomainURL()}/hr/reports/leavers_and_gainers_stat/?${newUrl}`)
    .then(({ data }) => data);
}

export function getLeaverData(key) {
  let { department, location, start_date, end_date } = key.queryKey[1];
  let url = new URL('https://example.com');
  let params = new URLSearchParams(url.search);
  if (department) params.set('department', department);
  if (location) params.set('location', location);
  if (start_date) params.set('start_date', start_date);
  if (end_date) params.set('department', end_date);
  const newUrl = params.toString();
  return axios
    .get(`${getBaseDomainURL()}/hr/reports/leavers_stat/?${newUrl}`)
    .then(({ data }) => data);
}

export function getGainerData(key) {
  let { department, location, start_date, end_date } = key.queryKey[1];
  let url = new URL('https://example.com');
  let params = new URLSearchParams(url.search);
  if (department) params.set('department', department);
  if (location) params.set('location', location);
  if (start_date) params.set('start_date', start_date);
  if (end_date) params.set('department', end_date);
  const newUrl = params.toString();
  return axios
    .get(`${getBaseDomainURL()}/hr/reports/gainers_stat/?${newUrl}`)
    .then(({ data }) => data);
}

export function getEmployeeCount(key) {
  let { department, location, start_date, end_date } = key.queryKey[1];
  let url = new URL('https://example.com');
  let params = new URLSearchParams(url.search);
  if (department) params.set('department', department);
  if (location) params.set('location', location);
  if (start_date) params.set('start_date', start_date);
  if (end_date) params.set('department', end_date);
  const newUrl = params.toString();
  return axios
    .get(`${getBaseDomainURL()}/hr/reports/gender_data_stat/?${newUrl}`)
    .then(({ data }) => data);
}

export function getEmployeeCountData() {
  return axios
    .get(`${getBaseDomainURL()}/hr/reports/gender_departmental_breakdown/`)
    .then(({ data }) => data);
}

export function getAgeProfileCount(key) {
  let { department, location, start_date, end_date } = key.queryKey[1];
  let url = new URL('https://example.com');
  let params = new URLSearchParams(url.search);
  if (department) params.set('department', department);
  if (location) params.set('location', location);
  if (start_date) params.set('start_date', start_date);
  if (end_date) params.set('department', end_date);
  const newUrl = params.toString();
  return axios
    .get(`${getBaseDomainURL()}/hr/reports/age_data_stat/?${newUrl}`)
    .then(({ data }) => data);
}

export function getAgeProfileData() {
  return axios
    .get(`${getBaseDomainURL()}/hr/reports/age_departmental_breakdown/`)
    .then(({ data }) => data);
}

export function getSalaryCount(key) {
  let { department, location, start_date, end_date } = key.queryKey[1];
  let url = new URL('https://example.com');
  let params = new URLSearchParams(url.search);
  if (department) params.set('department', department);
  if (location) params.set('location', location);
  if (start_date) params.set('start_date', start_date);
  if (end_date) params.set('department', end_date);
  const newUrl = params.toString();
  return axios
    .get(`${getBaseDomainURL()}/hr/reports/compensation_data_stat/?${newUrl}`)
    .then(({ data }) => data);
}

export function getSalaryCountData() {
  return axios
    .get(
      `${getBaseDomainURL()}/hr/reports/compensation_departmental_breakdown/`
    )
    .then(({ data }) => data);
}

export function terminateEmployee({ id, payload }) {
  return axios
    .post(`${getBaseDomainURL()}/employees/${id}/terminate/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getTimeOffRecord(status) {
  return axios
    .get(`${getBaseDomainURL()}/timeoff_taken/?status=active`)
    .then(({ data }) => data);
}
